import React, {Component} from 'react';
import {ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent} from 'react-pro-sidebar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faClipboardList, faDatabase, faUsers, faTimes, faFolder} from '@fortawesome/free-solid-svg-icons'
import {Link, Redirect} from "react-router-dom";
import Logout from "../pages/auth/Logout";
import logo from '../asset/images/logo.svg'

class NavSidebar extends Component {

    constructor(props){
        super();
        this.state={
            collapsed:false
        }
    }

    handleCollapsedSidebar = (e) => {
        if(this.state.collapsed){
            this.setState({collapsed: false})
        }else{
            this.setState({collapsed: true})
        }
        e.preventDefault()
    };


    render() {
        if(!localStorage.getItem('token')){
            return (
                <Redirect to={'login'} />
            )
        }

        return (
            <ProSidebar
                collapsed={this.state.collapsed}
                breakPoint="md"
            >
                {
                    (() => {
                        if (this.state.collapsed) {
                            return <div className="menu-btn open-menu"  onClick={this.handleCollapsedSidebar}><FontAwesomeIcon icon={faBars}/></div>
                        } else {
                            return <div className="menu-btn"  onClick={this.handleCollapsedSidebar}><FontAwesomeIcon icon={faTimes} /></div>
                        }
                    })()
                }
                <SidebarHeader>
                    <Link to="/">Swiss Pim</Link>
                    <Link to="/">{this.state.collapsed}</Link>
                </SidebarHeader>
                <SidebarContent>

                    <Menu iconShape="square">
                        <MenuItem icon={<FontAwesomeIcon icon={faClipboardList } />}><Link to="/categories">All Categories</Link></MenuItem>
                        <MenuItem icon={<FontAwesomeIcon icon={faClipboardList } />}><Link to="/">All Products</Link></MenuItem>
                        <MenuItem icon={<FontAwesomeIcon icon={faDatabase } />}><Link to="/platforms">All Platforms</Link></MenuItem>
                        <MenuItem icon={<FontAwesomeIcon icon={faUsers } />}><Link to="/">All Users</Link></MenuItem>
                        <MenuItem icon={<FontAwesomeIcon icon={faFolder } />}><Link to="/mediacenter">Media Center</Link></MenuItem>
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <div  className="sidebar-btn-wrapper" >
                        <Logout />
                    </div>
                </SidebarFooter>
            </ProSidebar>

        );
    }
}

export default NavSidebar;