import React, { Component, Fragment } from 'react';
import NavSidebar from "../../components/NavSidebar";
import SingleFile from "../../components/mediacenter/SingleFile";



class ShowFile extends Component {

    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar />
                    <main className="container-main">
                        <SingleFile />
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default ShowFile;