import {useHistory} from "react-router-dom";
import React from "react";
import {useMutation} from "@apollo/client";
import {LOGOUT_USER} from "../../queries/user-queries";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPowerOff} from '@fortawesome/free-solid-svg-icons';

export default function Logout() {
    let history = useHistory();
    const [logoutUser, { data,loading, error }] = useMutation(LOGOUT_USER, {
        onCompleted: (data) =>   history.replace({
            pathname: '/login',
            state: {
                dataMessage : data,
            }
        })
    });


    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    function handleClick() {
        logoutUser();
        localStorage.clear();
    }
    return (
        <div variant="secondary" onClick={handleClick}>
            <span className="logout-icon"><FontAwesomeIcon icon={faPowerOff}/></span><span className="logout-text">Logout</span>
        </div>
    );
}