import React, {Component, useState} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {Row, Col, Button, Table, Modal} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle, faPlus} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {faPenSquare} from '@fortawesome/free-solid-svg-icons'
import {CATEGORIES_QUERY, DELETE_CATEGORY} from '../../queries/category-queries'


export default function AllCategories() {
    const [show, setShow] = useState(false);
    const [category, setCategory] = useState(true);

    const {data, loading, error} = useQuery(CATEGORIES_QUERY);

    const [deleteCategory] = useMutation(DELETE_CATEGORY);


    let handleClose = () => {
        setShow(false)
    }

    function handleShow(cat) {
        setCategory(cat);
        setShow(true);
    }

    let handleClick = async (catId) => {
        try {
            await deleteCategory({
                variables: {
                    id: catId
                },
                optimisticResponse: {
                    deleteCategory: {
                        id: catId
                    }
                },
                refetchQueries: [
                    {
                        query: CATEGORIES_QUERY
                    }
                ],
                awaitRefetchQueries: true
            })
        } catch (error) {
        }

    }

    if (loading) return "Loading...";
    if (error) return <pre>{error.message}</pre>


    return (

        <div>
            <h1>All Categories</h1>
            <Row>
                <Col className="mb-3 align-right"><Link to="/add-category"><Button variant="primary" size="sm">
                    <FontAwesomeIcon icon={faPlus}/> Add New Category
                </Button></Link></Col>
            </Row>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th width="80px">Id</th>
                    <th width="80px" align="center">Status</th>
                    <th>Title</th>
                    <th>Subtitle</th>
                    <th>Teaser</th>
                    <th>Description</th>
                    <th>Platforms</th>
                    <th>Parent Category</th>
                    <th width="100px" align="center">Actions</th>
                </tr>
                </thead>
                <tbody>

                {data.categories.map((category, id) => (

                    <tr key={category.id}>
                        <td>{category.id}</td>
                        <td align="center">
                            <div>
                                <FontAwesomeIcon size="1x" icon={faCircle} className={
                                    (() => {
                                        if (category.hidden == 0) {
                                            return "circle-green"
                                        } else {
                                            return "circle-red"
                                        }
                                    })()
                                }/>
                            </div>
                        </td>
                        <td><Link to={`/category/${category.id}`}>{category.title}</Link></td>
                        <td>{category.subtitle}</td>
                        <td>{category.teaser}</td>
                        <td dangerouslySetInnerHTML={{__html: category.description}}></td>
                        <td>
                            {category.platforms.map((platform, i) => {
                                return <div key={i}>{platform.name}</div>
                            })}
                        </td>
                        {category.parent ? (
                            <td> {category.parent.title}</td>
                        ) : (
                            <td></td>
                        )}

                        <td align="center">
                            <Link className="btn btn-primary edit-btn btn-sm"
                                  to={`/edit-category/${category.id}`}><FontAwesomeIcon icon={faPenSquare}/></Link>
                            <Button variant="danger" key={id} size="sm" onClick={() => {
                                handleShow(category)
                            }}>
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete category: {category.title}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleClick(category.id);
                        handleClose()
                    }}>
                        delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}

