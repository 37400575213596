import React, {Component, Fragment} from 'react';
import UpdatePlatform from "../../components/platform/UpdatePlatform";
import NavSidebar from "../../components/NavSidebar";

class EditPlatform extends Component {
    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar />
                    <main className="container-main">
                        <UpdatePlatform />
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default EditPlatform;