import React, {Fragment} from 'react';
import {Button, Col, FloatingLabel, Form, Row, Spinner, Container} from "react-bootstrap";
import {FORGOT_PASSWORD} from '../../queries/user-queries'
import {useMutation} from "@apollo/client";
import {Link, Redirect} from "react-router-dom";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const ForgotPassword = () => {

    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
    })

    const validationOpt = { resolver: yupResolver(formSchema) }

    const { register, handleSubmit, reset, formState } = useForm(validationOpt)

    const { errors } = formState

    const [forgotPassword, {loading, data, error}] = useMutation(FORGOT_PASSWORD,{
        onCompleted: (data) => {
           window.localStorage.setItem('oldToken', data.forgotPasswordUser.token);
        }
    });

    function onFormSubmit(data) {
        const user = data
        forgotPassword({
            variables: {...user}
        });
        const form= document.getElementById("forgot-password");
        form.reset();
        return false
    }

    if (localStorage.getItem('token')) {
        return <Redirect to='/'/>
    }

    let loadingSpinner = '';
    if (loading) {
        loadingSpinner = (
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        )
    }

    let errorMessage = "";
    if (error) {
        errorMessage = (
                <div className="alert alert-danger" role="alart">
                    {error.message}<br/>
                    {error.graphQLErrors[0].extensions.reason}
                </div>
        )
    }
    let sussesMessage=""
    if (data) {
        console.log(data.forgotPasswordUser);
        sussesMessage = (
                <div className="alert alert-success" role="alart">
                     {data.forgotPasswordUser.message}
                </div>
        )
    }

    return (
        <Fragment>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} sm={6} md={4}>
                        <Form className="login-form" onSubmit={handleSubmit(onFormSubmit)} id="forgot-password">
                            {errorMessage}
                            {sussesMessage}
                            <div>
                                <h1>FORGOT YOUR PASSWORD?</h1>
                                <p>Please enter your username email address. Instructions for resetting the password
                                    will be
                                    immediately emailed to you.</p>
                                <FloatingLabel controlId="floatingEmail" label="Email" className="mb-3">
                                    <input
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        {...register('email')}
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.email?.message}
                                    </div>
                                </FloatingLabel>

                                <Button variant="primary" type="submit">
                                    {loadingSpinner} Reset password
                                </Button>
                                <p className="mt-2"><Link to='/login'>Login</Link></p>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>

    );
}

export default ForgotPassword;