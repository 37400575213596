import {gql} from '@apollo/client';

export const LOGIN_USER = gql`
    mutation ($username: String!, $password: String! ){
        loginUser(input: {
            username: $username,
            password: $password
        }) {
            access_token
            refresh_token
            expires_in
            token_type
            message
            user {
                id
                email
                name
            }
        }
    }
`
export const LOGOUT_USER = gql`
    mutation {
        logoutUser{
            status
            message
        }
    }
`

export const FORGOT_PASSWORD = gql`
    mutation ($email: String!){
        forgotPasswordUser(input: {
            email: $email
        }){
            status
            message
            token
        }
    }
`

export const RESET_PASSWORD = gql`
    mutation ($email: String!, $password: String!, $password_confirmation: String!, $token: String!){
        updateForgottenPasswordUser(
            input:{email:$email,password:$password,password_confirmation:$password_confirmation,token:$token}
        ){
            message
        }
    }
`
