import React, { Component, Fragment } from 'react'
import {Switch, Route} from "react-router-dom";
import Dashboard from '../pages/Dashboard'
import Categories from '../pages/category/Categories'
import CreateCategory from "../pages/category/CreateCategory";
import ShowCategory from "../pages/category/ShowCategory";
import EditCategory from "../pages/category/EditCategory";
import Platforms from "../pages/platform/Platforms";
import CreatePlatform from "../pages/platform/CreatePlatform";
import ShowPlatform from "../pages/platform/ShowPlatform";
import EditPlatform from "../pages/platform/EditPlatform";
import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import MediaCenter from "../pages/mediacenter/MediaCenter";
import MediaCenterFolder from "../pages/mediacenter/MediaCenterFolder";
import ShowFile from "../pages/mediacenter/ShowFile";



class AppRouter extends Component {

    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/categories" component={Categories} />
                    <Route exact path="/add-category" component={CreateCategory} />
                    <Route exact path="/category/:id" component={ShowCategory} />
                    <Route exact path="/edit-category/:id" component={EditCategory} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/platforms" component={Platforms} />
                    <Route exact path="/add-platform" component={CreatePlatform} />
                    <Route exact path="/platform/:id" component={ShowPlatform} />
                    <Route exact path="/edit-platform/:id" component={EditPlatform} />
                    <Route exact path="/mediacenter" component={MediaCenter} />
                    <Route exact path="/mediacenter/:id" component={MediaCenterFolder} />
                    <Route exact path="/file/:id" component={ShowFile} />
                </Switch>
            </Fragment>
        )
    }
}

export default AppRouter