import {gql} from '@apollo/client';

export const MEDIACENTER_QUERY = gql`
    {
        mediacenter{
            name
            type
            id
            uid
        }
    }    
`;

export const ADD_FOLDER = gql`
    mutation addFolder($name: String!, $parentId: ID){
        addFolder(
            name: $name
            parentId: $parentId
        ){
            name
            id
        }
    }
`;

export const GET_FOLDER = gql`
    query GetFolder($id: ID!) {
        folder(id: $id) {
            id
            parentId
            name
            uid
            files{
                name
                type
                id
                uid
            }
        }
    }
`;

export const DELETE_FOLDER = gql`
    mutation deleteFolder($id: ID!){
        deleteFolder(id: $id)
    }
`;

export const ADD_FILES = gql`
    mutation addFiles($files: [Upload], $parentId: ID ){
        addFiles(files: $files, parentId: $parentId)
    }
`;

export const GET_FILE = gql`
    query GetFile($id: ID!) {
        file(id: $id) {
            id
            name
            size
            extension
            identifier
            uid
            type
            parentId
        }
    }
`;

export const DELETE_FILE = gql`
    mutation deleteFile($id: ID!){
        deleteFile(id: $id)
    }
`;



