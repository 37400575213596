import React, { Component, Fragment } from 'react'
import AddPlatform from "../../components/platform/AddPlatform";
import NavSidebar from "../../components/NavSidebar";



class CreatePlatform extends Component {

    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar />
                    <main className="container-main">
                        <AddPlatform />
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default CreatePlatform;