import React, {Component, useState,useEffect} from 'react';
import {useQuery, gql, useMutation} from '@apollo/client';
import {Container, Row, Col, Button, FloatingLabel, Form, FormGroup, Modal} from 'react-bootstrap'
import {Link, useHistory, useParams} from "react-router-dom";
import {UPDATE_CATEGORY, CATEGORIES_QUERY, GET_CATEGORY} from '../../queries/category-queries'
import {GET_PLATFORMS} from "../../queries/platform-queries";
import Select from 'react-select'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import serialize from "form-serialize";
import RichTextEditor from 'react-rte';
import RootFolder from "../mediacenter/RootFolder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderPlus} from "@fortawesome/free-solid-svg-icons";


export default function UpdateCategory() {
    const [show, setShow] = useState(false);

    const id = useParams();
    const {data: data, refetch} = useQuery(GET_CATEGORY, {variables: id});
    const {data: allData, loading, error} = useQuery(CATEGORIES_QUERY);
    const {data: dataPlatforms} = useQuery(GET_PLATFORMS);
    const [editorData, setEditorData] = useState( RichTextEditor.createEmptyValue())
    useEffect(()=> {
        if (data && data.category) {
            setEditorData(RichTextEditor.createValueFromString(data.category.description, 'html'));
        }
    },[data]);

    const [isToggled, setToggled] = useState(true);
    const toggleTrueFalse = () => setToggled(!isToggled)

    const formSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required'),
        hidden: Yup.boolean(),
        subtitle: Yup.string(),
        teaser: Yup.string(),
        description: Yup.string(),
        platforms: Yup.array()
            .of(Yup.object().shape({
                label: Yup.string(),
                value: Yup.number()
            }))

    })

    const validationOpt = {resolver: yupResolver(formSchema)}

    const {register, handleSubmit, reset, formState} = useForm(validationOpt)

    const {errors} = formState

    const history = useHistory();
    const [updateCategory] = useMutation(UPDATE_CATEGORY, {
        onCompleted: () => history.push("/categories")
    });

    var platformOptions = [];
    if (dataPlatforms && dataPlatforms.selectPlatforms) {
        dataPlatforms.selectPlatforms.forEach(function (item) {
            platformOptions.push({label: item.name, value: item.id})
        });
    }
    var categoryOptions = [];
    if (allData && allData.categories && data && data.category) {
        allData.categories.forEach(function (itemCategory) {
            if(itemCategory.id !== data.category.id){
                categoryOptions.push({label: itemCategory.title, value: itemCategory.id})
            }

        });
    }

    function onFormSubmit(data) {
        const form = document.getElementById("form-category");
        const category = serialize(form, {hash: true, empty: true})

        category["description"]=editorData._cache.html;

        if (category["hidden"] === 'on') {
            category["hidden"] = false
        } else {
            category["hidden"] = true
        }
        if (category.platforms == "") {
            category.platforms = [];
        }
        updateCategory({
            variables: {...category},
            refetchQueries: [
                {
                    query: CATEGORIES_QUERY
                }
            ],
            awaitRefetchQueries: true
        });
        return false
    }

    function  onChange(value){
       value.toString('html');
       setEditorData(value)
    }

    let handleClose = () => {
        setShow(false);
    }
    function handleShow() {
        setShow(true);
    }


    if (data && data.category) {
        refetch();

        let {category: {hidden, title, subtitle, teaser, description, platforms, parent}} = data;
        let platformSelected = [];
        if (platforms) {
            platforms.forEach(function (item) {
                platformSelected.push({label: item.name, value: item.id})
            });
        }
        let categorySelected = [];
        if (parent) {
            categorySelected.push({label: parent.title, value: parent.id})
        }


        return (
            <div>
                <h1>{title}</h1>
                <Row>
                    <Col className="mb-3 align-right"><Link to={`/category/${id.id}`}><Button variant="primary"
                                                                                              size="sm">
                        Cancel
                    </Button></Link></Col>
                </Row>
                <Form onSubmit={handleSubmit(onFormSubmit)} id="form-category">
                    <Row>
                        <Col xs={12} sm={8} md={8}>
                            <FormGroup className="mb-3">
                                <Form.Check
                                    onClick={toggleTrueFalse}
                                    type="switch"
                                    id="hidden" {...register("hidden")}
                                    defaultChecked={!hidden}
                                    label={isToggled === true ? "Status Active" : "Status Hidden"}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={4} md={4}>
                            <Form.Label>Platforms</Form.Label>
                            <FloatingLabel controlid="floatingSelect" className="mb-3">
                                <Select name="platforms" options={platformOptions} isMulti={true}
                                        placeholder="Chose Platforms"
                                        defaultValue={platformSelected}>
                                </Select>


                            </FloatingLabel>
                        </Col>
                        <Col xs={12} sm={4} md={4}>
                            <Form.Label>Parent Category</Form.Label>
                            <FloatingLabel controlid="floatingSelect" className="mb-3">
                                <Select name="parent" options={categoryOptions} isMulti={false}
                                        placeholder="Chose Parent Category"
                                        defaultValue={categorySelected}>
                                </Select>


                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={8} md={8}>
                            <input type="hidden" name="id" value={id.id}/>
                            <FloatingLabel controlid="floatingInput" label="Title" className="mb-3">
                                <input
                                    name="title"
                                    type="text"
                                    placeholder="Title"
                                    defaultValue={title}
                                    {...register('title')}
                                    className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">
                                    {errors.title?.message}
                                </div>
                            </FloatingLabel>
                            <FloatingLabel controlid="floatingSubtitle" label="Subtitle" className="mb-3">
                                <input
                                    defaultValue={subtitle}
                                    name="subtitle"
                                    type="text"
                                    placeholder="Subtitle"
                                    {...register('subtitle')}
                                    className={`form-control ${errors.subtitle ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">
                                    {errors.subtitle?.message}
                                </div>
                            </FloatingLabel>

                            <FloatingLabel controlid="floatingTeser" label="Teaser" className="mb-3">
                                <textarea
                                    defaultValue={teaser}
                                    name="teaser"
                                    type="textarea"
                                    placeholder="Teaser"
                                    {...register('teaser')}
                                    className={`form-control ${errors.teaser ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">
                                    {errors.teaser?.message}
                                </div>
                            </FloatingLabel>

                            <Form.Label>Description</Form.Label>
                            <RichTextEditor
                                value = {editorData}
                                name= "description"
                                onChange={onChange}
                                className="mb-3"
                            />
                            <Button variant="primary" size="sm" onClick={() => {
                                handleShow()
                            }}><FontAwesomeIcon icon={faFolderPlus}/> Add image</Button>
                            <br/>
                            <br/>

                            <Button variant="primary" type="submit">
                                Update Category
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mediacenter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <iframe src="/mediacenter" width="100%"/>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    return null;
}


