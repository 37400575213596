import React, { Component, Fragment } from 'react';
import AllPlatforms from "../../components/platform/AllPlatforms";
import NavSidebar from "../../components/NavSidebar";



class Platforms extends Component {

    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar />
                    <main className="container-main">
                        <AllPlatforms />
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default Platforms;