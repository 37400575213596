import React, {useEffect, useState} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {Row, Col, Card, Button, Table, Modal, FormGroup, Form, FloatingLabel, Spinner} from 'react-bootstrap'
import {Link, useHistory, useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faFolder,
    faFileAlt,
    faFolderPlus,
    faCloudUploadAlt,
    faFileImage,
    faTrashAlt,
    faReply
} from '@fortawesome/free-solid-svg-icons'
import {DELETE_FILE, GET_FILE} from "../../queries/mediacenter-queries";
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'


export default function SingleFile() {

    const args = useParams();
    const {data, refetch} = useQuery(GET_FILE, {variables: args});

    const [showDelete, setShowDelete] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState('');

    const history = useHistory();
    const [deleteFile] = useMutation(DELETE_FILE, {
        onCompleted: () => {
            handleCloseDelete()
            setLoadingSpinner('');
            if (data.file.parentId == 0) {
                history.push("/mediacenter")
            }else{
                history.push('/mediacenter/'+data.file.parentId)
            }
        }
    });

    let handleCloseDelete = () => {
        setShowDelete(false)
    }
    function handleShowDelete() {
        setShowDelete(true);
    }

    let handleClickDelete = async (itemId) => {
        setLoadingSpinner(<Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />);
        try {
            await deleteFile({
                variables: {
                    id: itemId
                },
                optimisticResponse: {
                    deleteFile: {id:itemId}
                }
            })
        } catch (error) {
        }
    }


    if (data && data.file) {
        return (

            <div>
                <h1>File</h1>
                <Row>

                    <Col className="mb-3 " xs={12} sm={8} md={6} >
                        <div className="item-file">
                            {(() => {
                                    if(data.file.type.includes('image') ){
                                        return <div className="image"><img src={data.file.identifier} /></div>
                                    }
                            })()}

                            <div className="text">

                                <b>Name:</b> <Link to={{ pathname: data.file.identifier }} target="_blank" >{data.file.name}</Link> <br/>
                                <b>Size:</b> {data.file.size} <br/>
                                <b>Type:</b> {data.file.type} <br/>

                            </div>
                        </div>
                        <Row className="mt-3">
                            <Col xs={12} sm={8} md={6}>
                                {(() => {
                                    if (data.file.parentId == 0) {
                                        return <Link to="/mediacenter"><FontAwesomeIcon icon={faReply}/> Back</Link>
                                    }else{
                                        return <Link to={`/mediacenter/${data.file.parentId}`}><FontAwesomeIcon icon={faReply}/> Back</Link>
                                    }
                                })()}
                            </Col>
                            <Col xs={12} sm={8} md={6} className="align-right">
                                <Button variant="danger"  onClick={handleShowDelete}>
                                    <FontAwesomeIcon icon={faTrashAlt}/> Delete
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete {data.file.name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={() => {handleClickDelete(data.file.uid)}}>
                            {loadingSpinner} Delete
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>
        );

    }
    return null;
}

