import React, {Fragment} from 'react';
import {Button, Col, FloatingLabel, Form, Row, Spinner, Container} from "react-bootstrap";
import {LOGIN_USER} from '../../queries/user-queries'
import {useMutation} from "@apollo/client";
import {Redirect, Link, useLocation} from "react-router-dom";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const Login = () => {
    const location = useLocation();
    const [loginUser, { loading, data, error }] = useMutation(LOGIN_USER)
    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password length should be at least 8 characters'),
        username: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
    })

    const validationOpt = { resolver: yupResolver(formSchema) }

    const { register, handleSubmit, reset, formState } = useForm(validationOpt)

    const { errors } = formState

    function onFormSubmit(data) {
        const user = data
        loginUser({
            variables: { ...user }
        })
        location.state = "";
        return false
    }
    // Store token if login is successful
    if (data && data.loginUser && data.loginUser.access_token) {
        window.localStorage.setItem('token', data.loginUser.access_token)
        // Redirect to home page
        return <Redirect to='/' />
    }
    if(localStorage.getItem('token')){
        return <Redirect to='/' />
    }
    let loadingSpinner='';
    let errorMessage="";
    let message="";

    if (loading){
        loadingSpinner=(
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        )
    }

    if(data && data.loginUser && data.loginUser.message){
        errorMessage=(
                <div className="alert alert-danger" role="alart">
                    {data.loginUser.message}
                </div>
        )

    }

    if(location.state && location.state.dataMessage.logoutUser){
        message=(
            <div className="alert alert-success" role="alart">
                {location.state.dataMessage.logoutUser.message}
            </div>
        )
    }
    if(location.state && location.state.dataMessage.updateForgottenPasswordUser){
        message=(
            <div className="alert alert-success" role="alart">
                {location.state.dataMessage.updateForgottenPasswordUser.message}
            </div>
        )
    }
    window.history.replaceState(null, '')
    return (
            <Fragment>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs={12} sm={6} md={4}>
                            <Form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
                                {errorMessage}
                                {message}
                                <div>
                                    <h1>USER LOGIN</h1>
                                    <FloatingLabel  controlId="floatingEmail" label="Email" className="mb-3">
                                        <input
                                            name="username"
                                            type="email"
                                            placeholder="Email"
                                            {...register('username')}
                                            className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.username?.message}
                                        </div>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                                        <input
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            {...register('password')}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">{errors.password?.message}</div>
                                    </FloatingLabel>
                                    <Button variant="primary" type="submit">
                                        {loadingSpinner}  Login
                                    </Button>
                                    <p className="mt-2"><Link to='/forgot-password'>Forgot password</Link></p>

                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>

            </Fragment>

        );
}

export default Login;