import React, { Component, Fragment } from 'react'
import AllCategories from "../../components/category/AllCategories";
import NavSidebar from "../../components/NavSidebar";



class Categories extends Component {

    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar />
                    <main className="container-main">
                        <AllCategories />
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default Categories;