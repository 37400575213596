import React, { Component, Fragment, useState  } from 'react'
import NavBar from "../components/NavBar";
import NavSidebar from "../components/NavSidebar";



class Dashboard extends Component {



    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar
                        collapsed={this.props.collapsed}
                    />
                    <main className="container-main">
                        {this.props.collapsed}
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default Dashboard;