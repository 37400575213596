import React, {Fragment} from 'react';
import {Button, Col, FloatingLabel, Form, Row, Spinner, Container} from "react-bootstrap";
import {RESET_PASSWORD} from '../../queries/user-queries'
import {useMutation} from "@apollo/client";
import {Redirect, useHistory} from "react-router-dom";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const ResetPassword = () => {
    let history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password length should be at least 8 characters'),
        password_confirmation: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords must and should match'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
    })

    const validationOpt = { resolver: yupResolver(formSchema) }

    const { register, handleSubmit, reset, formState } = useForm(validationOpt)

    const { errors } = formState

    const [updateForgottenPasswordUser, { loading, data, error }] = useMutation(RESET_PASSWORD,{
        onCompleted: (data) => {
            localStorage.clear('oldToken');
            history.replace({
                pathname: '/login',
                state: {
                    dataMessage : data,
                }
            })
        }
    })

    function onFormSubmit(data) {
        const user = data
        user["token"] = localStorage.getItem('oldToken');
        updateForgottenPasswordUser({
            variables: { ...user }
        })
        return false
    }

    if(!queryParams.get('token') && localStorage.getItem('oldToken')!=queryParams.get('token')){
        return <Redirect to='/login' />
    }

    if(localStorage.getItem('token')){
        return <Redirect to='/' />
    }

    let loadingSpinner='';
    let errorMessage="";
    let message="";

    if (loading){
        loadingSpinner=(
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        )
    }


    if(error){
        errorMessage=(
            <div className="alert alert-danger" role="alart">
                {error.message}<br/>

            </div>
        )
    }


    return (
        <Fragment>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} sm={6} md={4}>
                        <Form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
                            {errorMessage}
                            <div>
                                <h1>RESET PASSWORD</h1>
                                <FloatingLabel  controlId="floatingEmail" label="Email" className="mb-3">
                                    <input
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        {...register('email')}
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.email?.message}
                                    </div>
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingPassword" label="New Password" className="mb-3">
                                    <input
                                        name="password"
                                        type="password"
                                        placeholder="New Password"
                                        {...register('password')}
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingConfirmPassword" label="Confirm Password" className="mb-3">
                                    <input
                                        name="password_confirmation"
                                        type="password"
                                        placeholder="Confirm Password"
                                        {...register('password_confirmation')}
                                        className={`form-control ${
                                            errors.password_confirmation ? 'is-invalid' : ''
                                        }`}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.password_confirmation?.message}
                                    </div>
                                </FloatingLabel>
                                <Button variant="primary" type="submit">
                                    {loadingSpinner}  Reset
                                </Button>

                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>

        </Fragment>

    );
}

export default ResetPassword;