import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {Form, FloatingLabel, Button, Row, Col, FormGroup} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import {ADD_CATEGORY, CATEGORIES_QUERY} from '../../queries/category-queries'
import {GET_PLATFORMS} from '../../queries/platform-queries'
import Select from 'react-select'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import serialize from "form-serialize";
import RichTextEditor from 'react-rte';


export default function AddCategory() {
    const history = useHistory();
    const {data: data, loading, error} = useQuery(CATEGORIES_QUERY);
    const {data: dataPlatforms} = useQuery(GET_PLATFORMS);
    const [editorData, setEditorData] = useState(
        RichTextEditor.createEmptyValue()
    );

    const [isToggled, setToggled] = useState(true);
    const toggleTrueFalse = () => setToggled(!isToggled)
    const formSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required'),
        hidden: Yup.boolean(),
        subtitle: Yup.string(),
        image: Yup.string(),
        teaser: Yup.string(),
        description: Yup.string(),
        platforms: Yup.array()
            .of(Yup.object().shape({
                label: Yup.string(),
                value: Yup.number()}))

    })

    const validationOpt = {resolver: yupResolver(formSchema)}

    const {register, handleSubmit, reset, formState} = useForm(validationOpt)

    const {errors} = formState


    const [addCategory, {dataAdd}] = useMutation(ADD_CATEGORY, {
        onCompleted: () => history.push("/categories")
    });

    var platformOptions = [];
    if (dataPlatforms && dataPlatforms.selectPlatforms) {
        dataPlatforms.selectPlatforms.forEach(function (item) {
            platformOptions.push({label: item.name, value: item.id})
        });
    }
    var categoryOptions = [];
    if (data && data.categories) {
        data.categories.forEach(function (itemCategory) {
            categoryOptions.push({label: itemCategory.title, value: itemCategory.id})
        });
    }


    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    function onFormSubmit(data) {
         const form= document.getElementById("form-category");
         const category = serialize(form, {hash: true, empty: true});

        console.log(data.image);

         category["description"]=editorData._cache.html;

        if (category["hidden"]==='on'){
            category["hidden"]=false
        }else{
            category["hidden"]=true
        }
        if(category.platforms==""){
            category.platforms = [];
        }
        if(category.parent==""){
            category.parent = null;
        }
        console.log(category);
        addCategory({
            variables: {...category},
            refetchQueries: [
                {
                    query: CATEGORIES_QUERY
                }
            ],
            awaitRefetchQueries: true
        });

        return false
    }

    function  onChange(value){
        value.toString('html');
        setEditorData(value);
    }

    const onChangeFile = (e) => {
        const file = e.target.files[0];
        //const storageRef = app.storage().ref()
        //const fileRef = storageRef.child(file.name)
        // fileRef.put(file).then(() => {
        //     console.log("Uploaded a file")
        // })
        console.log(file);
    }

    return (
        <div>
            <h1>Add New Category</h1>
            <Form className="mt-5" onSubmit={handleSubmit(onFormSubmit)} id="form-category">
                <Row>
                    <Col xs={12} sm={8} md={8}>
                        <FormGroup className="mb-3">
                            <Form.Check
                                onClick={toggleTrueFalse}
                                type="switch"
                                id="hidden" {...register("hidden")}
                                defaultChecked
                                label={isToggled === true ? "Status Active" : "Status Hidden"}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={4} md={4}>
                        <Form.Label>Platforms</Form.Label>
                        <FloatingLabel controlid="floatingSelect" className="mb-3" >
                            <Select  name="platforms" options={platformOptions} isMulti={true}
                                    placeholder="Chose Platforms">
                            </Select>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} sm={4} md={4}>
                        <Form.Label>Parent Category</Form.Label>
                        <FloatingLabel controlid="floatingSelect" className="mb-3" >
                            <Select  name="parent" options={categoryOptions} isMulti={false}
                                    placeholder="Chose Parent Category">
                            </Select>


                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={8} md={8}>
                        <FloatingLabel controlid="floatingInput" label="Title" className="mb-3">
                            <input
                                name="title"
                                type="text"
                                placeholder="Title"
                                {...register('title')}
                                className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">
                                {errors.title?.message}
                            </div>
                        </FloatingLabel>
                        <FloatingLabel controlid="floatingSubtitle" label="Subtitle" className="mb-3">
                            <input
                                name="subtitle"
                                type="text"
                                placeholder="Subtitle"
                                {...register('subtitle')}
                                className={`form-control ${errors.subtitle ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">
                                {errors.subtitle?.message}
                            </div>
                        </FloatingLabel>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Category image</Form.Label>
                            <Form.Control type="file" name="image" {...register('image')} onChange={onChangeFile} />
                        </Form.Group>

                        <FloatingLabel controlid="floatingTeser" label="Teaser" className="mb-3">
                            <textarea
                                name="teaser"
                                type="textarea"
                                placeholder="Teaser"
                                {...register('teaser')}
                                className={`form-control ${errors.teaser ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">
                                {errors.teaser?.message}
                            </div>
                        </FloatingLabel>

                        <Form.Label>Description</Form.Label>
                        <RichTextEditor
                            value = {editorData}
                            name= "description"
                            onChange={onChange}
                            className="mb-3"
                        />

                        <Button variant="primary" type="submit">
                            Add Category
                        </Button>
                    </Col>

                </Row>

            </Form>
        </div>
    );
}