import React, {Component, useEffect, useState} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {Row, Col, Button, Table, Modal, FormGroup, Form, FloatingLabel, Spinner} from 'react-bootstrap'
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faFolder,
    faFileAlt,
    faFolderPlus,
    faCloudUploadAlt,
    faFileImage,
    faReply, faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import {
    ADD_FILES,
    ADD_FOLDER,
    DELETE_FILE,
    DELETE_FOLDER,
    GET_FOLDER
} from "../../queries/mediacenter-queries";
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'


export default function Folder() {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [folder, setFolder] = useState(true);

    const args = useParams();
    const {data, refetch} = useQuery(GET_FOLDER, {variables: args});

    const formSchema = Yup.object().shape({
        name: Yup.string()
            .required('Title is required')
    })

    const validationOpt = {resolver: yupResolver(formSchema)}

    const {register, handleSubmit, reset, formState} = useForm(validationOpt)

    const {errors} = formState

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);

    const [idFolder, setIdFolder] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState('');

    const [addFolder] = useMutation(ADD_FOLDER, {
        onCompleted: () => {
            handleClose();
            setLoadingSpinner('');
        }
    });
    const [deleteFolder] = useMutation(DELETE_FOLDER, {
        onCompleted: () => {
            handleCloseDelete();
            setLoadingSpinner('');
        }
    });
    const [addFiles] = useMutation(ADD_FILES, {
        onCompleted: () => {
            handleClose2();
            setLoadingSpinner('');
            setImageURLs([]);
        }
    });
    const [deleteFile] = useMutation(DELETE_FILE, {
        onCompleted: () => {
            handleCloseDelete()
            setLoadingSpinner('');
        }
    });

    useEffect(() => {
        if (data && data.folder) {
            setIdFolder(data.folder.uid);
        }
    }, [data]);

    useEffect(()=>{
        if (selectedImages.length < 1) return;
        const newImages = [];
        let isImage = false;
        selectedImages.forEach(image => {
            if (image.type.includes('image')){
                isImage = true;
            }else{
                isImage = false;
            }
            newImages.push({"url":URL.createObjectURL(image), "type":isImage, "name":image.name});
        });
        setImageURLs(newImages);
    },[selectedImages])

    let handleClose = () => {
        setShow(false)
    }
    function handleShow() {
        setShow(true);
    }

    let handleClose2 = () => {
        setShow2(false);
        setImageURLs([]);
    }
    function handleShow2() {
        setShow2(true);
    }

    let handleCloseDelete = () => {
        setShowDelete(false)
    }
    function handleShowDelete(item) {
        setFolder(item);
        setShowDelete(true);
    }

    function onFolderFormSubmit(data) {
        const folder = data

        setLoadingSpinner(<Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />);

        folder.parentId = idFolder;
        addFolder({
            variables: {...folder}
        });
        reset();
        return false
    }

    function onFileFormSubmit() {
        const files = selectedImages;
        const parentId = idFolder;

        setLoadingSpinner(<Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />);

        addFiles({
            variables: {files:files,parentId:parentId}
        });
        reset();
        return false
    }

    const onChangeFile = (e) => {
        const file = e.target.files[0];
        setSelectedImages([...e.target.files]);


    }


    let handleClickDelete = async (itemId, itemType) => {
        setLoadingSpinner(<Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />);
        if(itemType == 'folder'){
            try {
                await deleteFolder({
                    variables: {
                        id: itemId
                    },
                    optimisticResponse: {
                        deleteFolder: {id:itemId}
                    }
                })
            } catch (error) {
            }
        }else{
            try {
                await deleteFile({
                    variables: {
                        id: itemId
                    },
                    optimisticResponse: {
                        deleteFile: {id:itemId}
                    }
                })
            } catch (error) {
            }
        }
    }

    if (data && data.folder) {
        refetch();
        return (
            <div>
                <h1>Mediacenter</h1>
                <Row>

                    <Col className="mb-3 align-right">
                        <Button variant="primary" size="sm" className="top-add-img" onClick={() => {
                            handleShow2()
                        }}><FontAwesomeIcon icon={faCloudUploadAlt}/> Upload File</Button>
                        <Button variant="primary" size="sm" onClick={() => {
                            handleShow()
                        }}><FontAwesomeIcon icon={faFolderPlus}/> Add New Folder</Button></Col>
                </Row>

                <Table striped bordered hover className="mediacenter-table">
                    <thead>
                    <tr>

                        <th colSpan="2">{data.folder.name}</th>
                    </tr>
                    </thead>

                    <tbody>
                        {data.folder.files.map((subFolder, id) => (
                                <tr key={id}>
                                    <td>{(() => {
                                        if (subFolder.type == 'img') {
                                            return <div><FontAwesomeIcon icon={faFileImage} className="image-icon"/> <Link to={`/file/${subFolder.uid}`}>{subFolder.name}</Link></div>
                                        } else if (subFolder.type == 'doc') {
                                            return <div><FontAwesomeIcon icon={faFileAlt} className="doc-icon"/> <Link to={`/file/${subFolder.uid}`}>{subFolder.name}</Link></div>
                                        } else {
                                            return <div><FontAwesomeIcon icon={faFolder} className="folder-icon"/> <Link to={`/mediacenter/${subFolder.uid}`}>{subFolder.name}</Link></div>
                                        }
                                    })()} </td>
                                    <td align="center" width="80px">
                                        <Button variant="danger" key={id} size="sm" onClick={() => {handleShowDelete(subFolder)}}>
                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                        </Button>
                                    </td>

                                </tr>
                            ))}
                    </tbody>
                </Table>
                <Row>
                    <Col>
                        {(() => {
                            if (data.folder.parentId == 0) {
                               return <Link to="/mediacenter"><FontAwesomeIcon icon={faReply}/> Back</Link>
                            }else{
                                return <Link to={`/mediacenter/${data.folder.parentId}`}><FontAwesomeIcon icon={faReply}/> Back</Link>
                            }
                        })()}
                    </Col>
                </Row>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add new Folder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit(onFolderFormSubmit)} id="form-folder">
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <FloatingLabel controlId="folderName" label="Folder name">
                                            <Form.Control type="text" name="name"
                                                          placeholder="folder" {...register('name')} />
                                        </FloatingLabel>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Button className="mt-3" variant="primary" type="submit" >{loadingSpinner} Save</Button>
                                </Col>
                            </Row>

                        </Form>

                    </Modal.Body>

                </Modal>
                <Modal show={show2} onHide={handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add file</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload File</Form.Label>
                            <Form.Control type="file" name="image" multiple {...register('image')} onChange={onChangeFile}/>
                        </Form.Group>
                        {imageURLs.map((file,i) => {
                            if(file.type){
                                return <div key={i} className="small-img"><img  width={"100px"} src={file.url} /></div>
                            }else{
                                return <div key={i} className=""><FontAwesomeIcon icon={faFileAlt}  className="doc-icon"/> {file.name}</div>
                            }
                           }
                        )}

                        <div><Button className="mt-3" variant="primary" type="submit" onClick={() => {onFileFormSubmit()}}>{loadingSpinner}  Upload</Button></div>


                    </Modal.Body>

                </Modal>
                <Modal show={showDelete}  onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete {folder.type}: {folder.name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={() => {handleClickDelete(folder.uid, folder.type);}}>
                            {loadingSpinner} Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    return null;
}

