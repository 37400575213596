import React, {Component} from 'react';
import {useQuery} from '@apollo/client';
import {Row, Col, Button, FloatingLabel, FormLabel, Form} from 'react-bootstrap'
import {Link, useParams} from "react-router-dom";
import {GET_PLATFORM} from '../../queries/platform-queries'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faEdit} from "@fortawesome/free-solid-svg-icons";


export default function SinglePlatform() {

    const args = useParams();
    const {data,refetch} = useQuery(GET_PLATFORM, {variables: args} );

    if (data && data.platform) {
        refetch();
        const {platform: {name, hidden}} = data;
        return (
            <div>
                <h1>{name}  <FontAwesomeIcon size="1x" icon={faCircle} className={
                    (() => {
                        if (hidden==0) {
                            return "circle-green circle-icon"
                        } else {
                            return "circle-red circle-icon"
                        }
                    })()
                }/></h1>
                <Row>
                    <Col className="mb-3 align-right"><Link to={`/edit-platform/${args.id}`}><Button variant="primary"
                                                                                                     size="sm">
                        <FontAwesomeIcon icon={faEdit}/> Edit Platform
                    </Button></Link></Col>
                </Row>
                <Form>
                    <Row>
                        <Col xs={12} sm={8} md={8}>
                            <FloatingLabel controlid="floatingInput" label="Title" className="mb-3">
                                <Form.Control defaultValue={name} type="text" name="name" placeholder="Name"
                                              required={true} disabled={true}/>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return null;
}


