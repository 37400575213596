import React, {Component, useState,useEffect} from 'react';
import {useQuery, gql, useMutation} from '@apollo/client';
import {Container, Row, Col, Button, FloatingLabel, Form, FormGroup} from 'react-bootstrap'
import {Link, useHistory, useParams} from "react-router-dom";
import {GET_PLATFORM, PLATFORMS_QUERY, UPDATE_PLATFORM} from "../../queries/platform-queries";
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'


export default function UpdatePlatform() {

    const args = useParams();
    const {data: data,refetch} = useQuery(GET_PLATFORM, {variables: args});
    const {data: allData, loading, error} = useQuery(PLATFORMS_QUERY);

    const [isToggled, setToggled] = useState(true);
    const toggleTrueFalse = () => setToggled(!isToggled)

    useEffect(() => {
        if (data && data.platform) {
            setToggled(!data.platform.hidden)
        }

    }, [data]);

    const formSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        hidden: Yup.boolean(),
        id: Yup.number()
    })
    const validationOpt = {resolver: yupResolver(formSchema)}
    const {register, handleSubmit, reset, formState} = useForm(validationOpt)
    const {errors} = formState

    const history = useHistory();
    const [updatePlatform] = useMutation(UPDATE_PLATFORM, {
        onCompleted: () => history.push("/platforms")
    });


    function onFormSubmit(data) {
        const platform = data
        platform["hidden"] = !data['hidden']
        updatePlatform({
            variables: {...platform},
            refetchQueries: [
                {
                    query: PLATFORMS_QUERY

                }
            ],
        });
        return false
    }


    if (data && data.platform) {
        refetch()
        let {platform: {name, hidden}} = data;
        return (
            <div>
                <h1>{name}</h1>
                <Row>
                    <Col className="mb-3 align-right"><Link to={`/platform/${args.id}`}><Button variant="primary"
                                                                                              size="sm">
                        Cancel
                    </Button></Link></Col>
                </Row>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Row>
                        <Col xs={12} sm={8} md={8}>
                            <FormGroup className="mb-3">
                                <Form.Check
                                    onClick={toggleTrueFalse}
                                    type="switch"
                                    id="hidden" {...register("hidden")}
                                    defaultChecked={!hidden}
                                    label={isToggled === true ? "Status Active" : "Status Hidden"}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={8} md={8}>
                            <input type="hidden" name="id" {...register('id')} value={args.id}/>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlid="floatingInput" label="Name" >
                                    <input
                                        name="name"
                                        defaultValue={name}
                                        {...register('name')}
                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.name?.message}
                                    </div>
                                </FloatingLabel>
                            </FormGroup>
                            <Button variant="primary" type="submit">
                                Update Platform
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return null;
}


