import React, {Component, useState} from 'react';
import {useQuery,useMutation} from '@apollo/client';
import {Row, Col, Button, Table, Modal} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {faPenSquare} from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import {GET_PLATFORM, PLATFORMS_QUERY} from "../../queries/platform-queries";
import {DELETE_PLATFORM} from "../../queries/platform-queries";



export default function AllPlatforms() {
    const [show, setShow] = useState(false);
    const [platform, setPlatform] = useState(true);

    const {data, loading, error} = useQuery(PLATFORMS_QUERY);
    const [deletePlatform] = useMutation(DELETE_PLATFORM);


    let handleClose = () => {
        setShow(false)
    }
    function handleShow(item) {
        setPlatform(item);
        setShow(true);
    }
    let handleClick = async (itemId) => {
        try {
            await deletePlatform({
                variables: {
                    id: itemId
                },
                optimisticResponse: {
                    deletePlatform: {id:itemId}
                },
                refetchQueries: [
                    {
                        query: PLATFORMS_QUERY,GET_PLATFORM
                    }
                ],
                awaitRefetchQueries: true
            })
        } catch (error) {
        }
    }

    if (loading) return "Loading...";
    if (error) return <pre>{error.message}</pre>


    return (

        <div>
            <h1>All Platforms</h1>
            <Row>
                <Col className="mb-3 align-right"><Link to="/add-platform"><Button variant="primary" size="sm">
                    <FontAwesomeIcon icon={faPlus}/> Add New Platform
                </Button></Link></Col>
            </Row>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th width="80px">Id</th>
                    <th width="80px" align="center">Status</th>
                    <th>Name</th>
                    <th width="100px"  align="center"></th>
                </tr>
                </thead>
                <tbody>
                {data.platforms.map((platform, id) => (

                    <tr key={platform.id}>
                        <td>{platform.id}</td>
                        <td  align="center">
                            <div>
                                <FontAwesomeIcon size="1x" icon={faCircle} className={
                                    (() => {
                                        if (platform.hidden==0) {
                                            return "circle-green"
                                        } else {
                                            return "circle-red"
                                        }
                                    })()
                                }/>
                            </div>
                        </td>
                        <td><Link to={`/platform/${platform.id}`}>{platform.name}</Link></td>
                        <td  align="center">
                            <Link className="btn btn-primary edit-btn btn-sm" to={`/edit-platform/${platform.id}`}><FontAwesomeIcon icon={faPenSquare}/></Link>
                            <Button variant="danger" key={id} size="sm" onClick={() => {handleShow(platform)}}>
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </Button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={show}  onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete platform: {platform.name}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => {handleClick(platform.id); handleClose()}}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

