import React, {Component, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {Form,FormGroup, FloatingLabel,FormLabel, Button, Row, Col} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import {ADD_PLATFORM, PLATFORMS_QUERY} from '../../queries/platform-queries'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'



export default function AddPlatform() {
    const history = useHistory();
    const {data, loading, error} = useQuery(PLATFORMS_QUERY);

    const [isToggled, setToggled] = useState(true);
    const toggleTrueFalse = () => setToggled(!isToggled)

    const formSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        hidden: Yup.boolean()
    })
    const validationOpt = {resolver: yupResolver(formSchema)}
    const {register, handleSubmit, reset, formState} = useForm(validationOpt)
    const {errors} = formState

    const [addPlatform, {dataAdd}] = useMutation(ADD_PLATFORM, {
        onCompleted: () => history.push("/platforms")
    })


    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    function onFormSubmit(data) {
        const platform = data
        platform["hidden"] = !data['hidden']
        addPlatform({
            variables: {...platform},
            refetchQueries: [
                {
                    query: PLATFORMS_QUERY
                }
            ],
            awaitRefetchQueries: true
        });
        return false
    }

    return (
        <div>
            <h1>Add New Platform</h1>
            <Form className="mt-5" onSubmit={handleSubmit(onFormSubmit)}>
                <Row>
                    <Col xs={12} sm={8} md={8}>
                        <FormGroup className="mb-3">
                            <Form.Check
                                onClick={toggleTrueFalse}
                                type="switch"
                                id="hidden" {...register("hidden")}
                                defaultChecked
                                label={isToggled === true ? "Status Active" : "Status Hidden"}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={8} md={8}>
                        <FormGroup>
                            <FloatingLabel controlid="floatingInput" label="Name" className="mb-3">
                                <input
                                    name="name"
                                    placeholder="Name"
                                    {...register('name')}
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">
                                    {errors.name?.message}
                                </div>
                            </FloatingLabel>
                        </FormGroup>
                        <Button variant="primary" type="submit">
                            Add Platform
                        </Button>
                    </Col>

                </Row>

            </Form>
        </div>
    );
}