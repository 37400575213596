import React, { Component, Fragment } from 'react';
import NavSidebar from "../../components/NavSidebar";
import Folder from "../../components/mediacenter/Folder";



class MediaCenterFolder extends Component {

    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar />
                    <main className="container-main">
                        <Folder />
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default MediaCenterFolder;