import {gql} from '@apollo/client';

export const CATEGORIES_QUERY = gql`
    {
        categories(orderBy:{column:"id",order:ASC}) {
            id
            hidden
            title
            subtitle
            teaser
            description
            platforms{
                id
                name
            }
            parent{
                id
                title
            }
        }
    }
`;

export const GET_CATEGORY = gql`
    query GetCategory($id: ID!) {
        category(id: $id) {
            id
            title
            hidden
            subtitle
            teaser
            description
            platforms{
                id
                name
            } 
            parent{
                id
                title
            }
        }
    }
`;

export const ADD_CATEGORY = gql`
    mutation addCategory($hidden: Boolean,$title: String!, $subtitle: String, $teaser: String, $description: String, $platforms:[ID!],$parent:ID){
        addCategory(
           hidden:$hidden, title: $title, subtitle: $subtitle, teaser: $teaser,description: $description, platforms:{
               connect: $platforms
            }, parent:{
                connect: $parent
            }
        ){
            id
            
        }
    }
`;

export const UPDATE_CATEGORY = gql`
    mutation updateCategory($id: ID!, $hidden: Boolean, $title: String!, $subtitle: String, $teaser: String, $description: String, $platforms:[ID],$parent:ID){
        updateCategory(
            id:$id, hidden: $hidden, title: $title, subtitle: $subtitle, teaser: $teaser,description: $description, platforms:{
                sync: $platforms
            },parent:{
                sync: $parent
            }
        ){
            id
        }
    }
`;


export const DELETE_CATEGORY = gql`
    mutation deleteCategory($id: ID!){
        deleteCategory(id: $id){
            id
        }
    }
`;

