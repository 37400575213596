import React, { Component } from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom';
import { Navbar, Container, Button } from 'react-bootstrap'
import Logout from "../pages/auth/Logout";



class NavBar extends Component {

    render() {

        if(!localStorage.getItem('token')){
            return (
                <Redirect to={'login'} />
            )
        }
        return (

            <Navbar bg="light">
                <Container fluid={true}>
                <Navbar.Brand><Link to="/">Pim Api</Link></Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <Logout />
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        );
    }
}

export default NavBar;


