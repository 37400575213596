import {gql} from '@apollo/client';

export const PLATFORMS_QUERY = gql`
    {
        platforms(orderBy:{column:"id",order:ASC}) {
            id
            name
            hidden
        }
    }
`;

export const GET_PLATFORM = gql`
    query GetPlatform($id: ID!) {
        platform(id: $id) {
            name
            hidden
        }
    }
`;

export const GET_PLATFORMS = gql`
    {
        selectPlatforms {
            id
            name
        }
    }
`;

export const ADD_PLATFORM = gql`
    mutation addPlatform($name: String!, $hidden: Boolean){
        addPlatform(
            name: $name, hidden: $hidden, 
        ){
            id
        }
    }
`;
export const UPDATE_PLATFORM = gql`
    mutation updatePlatform($id: ID!, $name: String! $hidden: Boolean){
        updatePlatform(
            id:$id, name: $name, hidden: $hidden
        ){
            id
        }
    }
`;
export const DELETE_PLATFORM = gql`
    mutation deletePlatform($id: ID!){
        deletePlatform(id: $id){
            id
        }
    }
`;

