import React, { Component } from 'react';
import { useQuery } from '@apollo/client';
import {Row, Col, Button, FloatingLabel, Form, FormLabel} from 'react-bootstrap'
import { Link, useParams } from "react-router-dom";
import { GET_CATEGORY } from '../../queries/category-queries'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faEdit} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";


export default function SingleCategory() {

    const id = useParams();
    const { data } =  useQuery(GET_CATEGORY, { variables: id });

    if (data && data.category) {
        const { category: {hidden, title, subtitle, teaser, description, platforms,parent }} = data;
        let platformSelected = [];
        if (platforms) {
            platforms.forEach(function (item) {
                platformSelected.push({label: item.name, value: item.id})
            });
        }
        let categorySelected = [];
        if (parent) {
            categorySelected.push({label: parent.title, value: parent.id})
        }
        return(
            <div>
                <h1>{title} <FontAwesomeIcon size="1x" icon={faCircle} className={
                    (() => {
                        if (hidden==0) {
                            return "circle-green circle-icon"
                        } else {
                            return "circle-red circle-icon"
                        }
                    })()
                }/></h1>
                <Row>
                    <Col className="mb-3 align-right"><Link to={`/edit-category/${id.id}`}><Button variant="primary" size="sm">
                        <FontAwesomeIcon icon={faEdit}/> Edit Category
                    </Button></Link></Col>
                </Row>
                <Form>
                    <Row>
                        <Col xs={12} sm={4} md={4}>
                            <Form.Label>Platforms</Form.Label>
                            <FloatingLabel controlid="floatingSelect" className="mb-3">
                                <Select  name="platforms"  isMulti={true} isDisabled={true}
                                         placeholder="Chose Platforms"
                                         defaultValue={platformSelected}>
                                </Select>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} sm={4} md={4}>
                            <Form.Label>Parent Category</Form.Label>
                            <FloatingLabel controlid="floatingSelect" className="mb-3">
                                <Select  name="parent"  isMulti={false} isDisabled={true}
                                         placeholder="Chose Parent Category"
                                         defaultValue={categorySelected}>
                                </Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={8} md={8}>
                            <FloatingLabel  controlid="floatingInput" label="Title" className="mb-3">
                                <Form.Control defaultValue={title} type="text" name="title" placeholder="Title" required={true} disabled={true} />
                            </FloatingLabel>
                            <FloatingLabel controlid="floatingSubtitle" label="Subtitle" className="mb-3">
                                <Form.Control defaultValue={subtitle}  type="text" name="subtitle" placeholder="Subtitle"  disabled={true} />
                            </FloatingLabel>

                            <FloatingLabel controlid="floatingTeser" label="Teaser" className="mb-3">
                                <Form.Control defaultValue={teaser} as="textarea" placeholder="Teaser" name="teaser"  disabled={true} />
                            </FloatingLabel>
                            <Form.Label>Description</Form.Label>
                            <div dangerouslySetInnerHTML={{__html: description}}></div>
                        </Col>
                    </Row>


                </Form>
            </div>
            )
    }

    return null;
}


