import React, {Component, Fragment} from 'react';
import UpdateCategory from "../../components/category/UpdateCategory";
import NavSidebar from "../../components/NavSidebar";

class EditCategory extends Component {
    render() {
        return (
            <Fragment>
                <div className="app">
                    <NavSidebar />
                    <main className="container-main">
                        <UpdateCategory />
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default EditCategory;